import React from 'react';
import YouTubePlayer from 'components/YoutubePlayer';
import styles from './YoutubePlayerWrapper.module.scss';

const YoutubePlayer = ({ url, player }) => {
  return (
    <div className={styles.videoWrapper}>
      {
        player && player === "iFrame" ?
          <div className={styles.video}>
            <iframe 
              src={url} 
              frameborder="0"
              className={styles.iframe}
            ></iframe>
          </div>
        :
          <YouTubePlayer url={url} />
      }
    </div>
  );
};

export default YoutubePlayer;
