import { useStaticQuery, graphql } from 'gatsby';

export const useStaticQueryGetAuctionCatalogue = () => {
  const query = useStaticQuery(
    graphql`
      query auctionCatalogues {
        allContentfulCatalogues {
          nodes {
            auctions {
              contentful_id
              title: name
              subEvents {
                ... on ContentfulSubEventAuction {
                  name
                  startDate
                  endDate
                }
              }

              offers {
                ... on ContentfulOffer {
                  id
                  name
                  category
                  price
                  eligibleCustomerType {
                    title
                  }
                }
              }
              webpage__auction {
                slug
                featureImage {
                  description
                  fluid(maxWidth: 1800, quality: 100) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
              }
            }
          }
        }
        allContentfulCatalogueYearSubscription {
          nodes {
            title
            year
            yearCatalogueSubscriptionFees
            body {
              body
            }
          }
        }
      }
    `
  );
  return {
    catalogueAuctions: query.allContentfulCatalogues.nodes
      ? query.allContentfulCatalogues.nodes[0].auctions
      : [],
    allContentfulCatalogueYearSubscription:
      query.allContentfulCatalogueYearSubscription,
  };
};
