import _ from 'lodash';
import moment from 'moment';

const todayDate = moment().format('YYYY-MM-DDTHH:mm');

// get the upcoming events and related to auction
export const getUpcomingSubEvent = subEventAuction => {
  return (
    subEventAuction?.length &&
    subEventAuction.filter(
      subEvent =>
        subEvent.endDate > todayDate &&
        subEvent.auction &&
        subEvent.auction.length > 0 &&
        subEvent.auction[0].webpage__auction
    )
  );
};

// reorganize auctions data to collect the auctions
export const getUpcomingAuctions = validSubEvents => {
  let auctions = _.uniqBy(
    _.flatten(
      validSubEvents?.length && validSubEvents.map(subEvent => subEvent.auction)
    ),
    'id'
  ).map(auction => {
    return {
      ...auction,
      subEvents: [],
    };
  });

  validSubEvents?.length &&
    validSubEvents.forEach(subEvent => {
      subEvent.auction?.length &&
        subEvent.auction.forEach(auction => {
          auctions.find(au => au.id === auction.id).subEvents.push(subEvent);
        });
    });
  return auctions;
};

// get the featured vehicles from the auctions
export const getFeaturedVehicles = auctions => {
  return _.flatten(
    auctions?.length &&
      auctions
        .map(
          auction =>
            auction.lot?.length &&
            _.uniqBy(
              auction.lot
                .filter(lot => lot.featured)
                .map(lot => ({
                  ...lot.item,
                  slug: lot.slug ? lot.slug : '#',
                  name: auction.name,
                  webpage__auction: auction.webpage__auction,
                })),
              'id'
            )
        )
        .filter(el => el)
  );
};
