import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReadMoreLess from 'components/ReadMoreLess';
import Button from 'components/Button';
import CTA from 'components/CTA';
import styles from './titleDescriptionCollapse.module.scss';

const buttonProps = PropTypes.shape({
  title: PropTypes.string,
  onClick: PropTypes.func,
});

const TitleDescriptionCollapse = ({
  description,
  title,
  marginTop,
  collapse,
  buttons,
  dates,
  locations,
  topButtons,
  backgroundColor,
  isMarkdown,
  callToActions,
  CTAbottom,
  CTAtop,
  children,
}) => {
  return (
    <div
      className={styles.consignGooding}
      style={{ marginTop, backgroundColor }}
    >
      <div className={styles.innerSection}>
        <div className={styles.headingSection}>
          <h2>{title}</h2>
          <div className={styles.subTitleWrapper}>
            {dates &&
              dates.map((date, index) => (
                <p
                  className={date.bold ? styles.bold : ''}
                  key={`date${index}_${index}`}
                >
                  {date.title}
                </p>
              ))}
          </div>
          {children}
          <div className={styles.subTitleWrapper}>
            {locations &&
              locations.map((location, index) => (
                <p key={`location${index}_${index}`}>{location.title}</p>
              ))}
          </div>

          {topButtons && topButtons.length > 0 && (
            <div>
              {topButtons.map((button, index) => (
                <Button
                  key={`titleDescriptionCollapseTopButton${index}`}
                  handleClick={() =>
                    _.isFunction(button.onClick) && button.onClick()
                  }
                  type={`app-transparent-blue-button ${styles.appTransparentBlueButton}`}
                >
                  {button.title}
                </Button>
              ))}
            </div>
          )}
          {CTAtop && callToActions?.length && (
            <div>
              <CTA
                callToActions={callToActions}
                extraStyle={`${styles.appTransparentBlueButton}`}
              />
            </div>
          )}
        </div>

        <div>
          {collapse ? (
            <ReadMoreLess
              content={description}
              isMarkdown={isMarkdown}
              shadowColor={backgroundColor || '#f5f5f5'}
            />
          ) : (
            <>
              {!isMarkdown && <p>{description}</p>}
              {isMarkdown && (
                <div dangerouslySetInnerHTML={{ __html: description }}></div>
              )}
            </>
          )}
        </div>
        {buttons && buttons.length && (
          <div>
            {buttons.map((button, index) => (
              <Button
                key={`titleDescriptionCollapseButton${index}`}
                handleClick={() =>
                  _.isFunction(button.onClick) && button.onClick()
                }
                type={`app-transparent-blue-button ${styles.appTransparentBlueButton}`}
              >
                {button.title}
              </Button>
            ))}
          </div>
        )}

        {CTAbottom && callToActions?.length && (
          <div>
            <CTA
              callToActions={callToActions}
              extraStyle={`${styles.appTransparentBlueButton}`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

TitleDescriptionCollapse.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  marginTop: PropTypes.number,
  backgroundColor: PropTypes.string,
  collapse: PropTypes.bool,
  buttons: PropTypes.arrayOf(buttonProps),
  topButtons: PropTypes.arrayOf(buttonProps),
  subTitles: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        bold: PropTypes.bool,
      })
    )
  ),
};

TitleDescriptionCollapse.defaultProps = {
  marginTop: 0,
  title: 'Submit Your Vehicle',
  collapse: true,
  backgroundColor: '#f5f5f5',
};

export default TitleDescriptionCollapse;
