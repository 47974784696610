import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import styles from './readMoreLess.module.scss';

const ReadMoreLess = ({
  content,
  shadowColor = '#ffffff',
  isMarkdown,
  cutAfter = 600,
  maxHeight = 315,
  withShadow = true,
}) => {
  const [readMore, setReadMore] = useState(false);
  const contentLength = content ? content.length : 0;
  const showReadMoreButton = contentLength > cutAfter;

  return (
    <div className={styles.appReadMore}>
      <div
        className={!readMore ? styles.text : ''}
        style={{
          maxHeight: !readMore && showReadMoreButton ? maxHeight : 'unset',
        }}
      >
        {Array.isArray(content) ? (
          content.map((line, key) => <p key={key}>{line}</p>)
        ) : (
          <>
            {!isMarkdown && <p>{content}</p>}
            {isMarkdown && (
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
            )}
          </>
        )}
      </div>
      {!readMore && showReadMoreButton && withShadow && (
        <div
          className={styles.shadow}
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0), ${shadowColor})`,
          }}
        ></div>
      )}
      {!readMore && showReadMoreButton && (
        <Button
          type={styles.readMoreBtn}
          handleClick={() => {
            setReadMore(!readMore);
          }}
        >
          READ MORE
          <FontAwesomeIcon className={styles.plusIcon} icon={faPlus} />
        </Button>
      )}
      {readMore && showReadMoreButton && (
        <Button
          type={styles.readMoreBtn}
          handleClick={() => {
            setReadMore(!readMore);
          }}
        >
          READ LESS
          <FontAwesomeIcon className={styles.plusIcon} icon={faMinus} />
        </Button>
      )}
    </div>
  );
};

ReadMoreLess.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  shadowColor: PropTypes.string,
  cutAfter: PropTypes.number,
};

export default ReadMoreLess;
